import React from 'react'
import Link from 'next/link'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Paper from '@material-ui/core/Paper'
import Button from 'Components/Button'
import PropTypes from 'prop-types'

const SchoolLandingPage = ({ data }) => {
  return (
    <div>
      {/* Hero section */}
      <section className="bg-blue-500">
        <Container align="center">
          <Box py={8}>
            <Typography variant="h3" className="text-white font-semibold">
              {data.mainTitle.title}
            </Typography>
            <Box py={3}>
              <Typography variant="h6" className="text-white font-semibold">
                {data.mainTitle.subtitle}
              </Typography>
            </Box>
            <Hidden smDown>
              <Box py={4} />
            </Hidden>
            <Hidden mdUp>
              <Box py={2} />
            </Hidden>
            <Link href="/courses">
              <Button
                color="inherit"
                bgColor="bg-green-500"
                hoverBgColor="bg-green-600"
                size="large"
                className="my-2 shadow-lg text-white py-3 px-5 hover:shadow-md"
              >
                {data.mainTitle.buttonText}
              </Button>
            </Link>
          </Box>
        </Container>
      </section>
      {/* Content Intro section */}
      <section>
        <Container align="center">
          <Box my={13}>
            <Box style={{ maxWidth: '700px' }} pb={5}>
              <Box pb={5}>
                <Typography
                  variant="h4"
                  className="font-semibold text-gray-700"
                >
                  {data.title2.title}
                </Typography>
              </Box>
              <Typography variant="h6">{data.title2.subtitle}</Typography>
            </Box>
            <div className="grid grid-cols-2" style={{ maxWidth: '1000px' }}>
              <div className="col-span-2 md:col-span-1">
                <Paper className="h-full text-left m-2 rounded-none bg-blue-500 max-w-screen-sm">
                  <img
                    src="/images/landing/learning.png"
                    alt="learning"
                    className="w-full h-full"
                  />
                </Paper>
              </div>
              <div className="col-span-2 md:col-span-1">
                <Paper className="h-full text-left m-2 rounded-none bg-blue-500 max-w-screen-sm">
                  <Box py={6} px={4}>
                    <Typography
                      variant="h6"
                      className="font-semibold text-white"
                    >
                      {data.imageCard.title}
                    </Typography>
                    <Box pt={1}>
                      <Typography
                        variant="h4"
                        className="font-semibold text-white"
                      >
                        {data.imageCard.subtitle}
                      </Typography>
                    </Box>
                    <Box py={3}>
                      <Typography className="text-white">
                        {data.imageCard.description}
                      </Typography>
                    </Box>
                    <Link href="/courses">
                      <Button className="text-white py-2 px-4 font-bold tracking-wider my-2 mx-0 hover:bg-white hover:text-gray-600">
                        {data.imageCard.buttonText}
                      </Button>
                    </Link>
                  </Box>
                </Paper>
              </div>
            </div>
          </Box>
        </Container>
      </section>

      {/* Fun facts section */}
      <section className="p-6 bg-blue-500">
        <Container align="center">
          <div className="grid grid-cols-3">
            <div className="col-span-3 md:col-span-1">
              <Box p={2}>
                <div>
                  <Typography
                    variant="h3"
                    align="center"
                    className="font-semibold text-white"
                  >
                    {data.number1.subtitle}
                  </Typography>
                </div>
                <div>
                  <Typography variant="h6" className="text-white">
                    {data.number1.title}
                  </Typography>
                </div>
              </Box>
            </div>

            <div className="col-span-3 md:col-span-1">
              <Box p={2}>
                <div>
                  <Typography
                    variant="h3"
                    align="center"
                    className="font-semibold text-white"
                  >
                    {data.number2.subtitle}
                  </Typography>
                </div>
                <div>
                  <Typography variant="h6" className="text-white">
                    {data.number2.title}
                  </Typography>
                </div>
              </Box>
            </div>

            <div className="col-span-3 md:col-span-1">
              <Box p={2}>
                <div>
                  <Typography
                    variant="h3"
                    align="center"
                    className="font-semibold text-white"
                  >
                    {data.number3.subtitle}
                  </Typography>
                </div>
                <div>
                  <Typography variant="h6" className="text-white">
                    {data.number3.title}
                  </Typography>
                </div>
              </Box>
            </div>
          </div>
        </Container>
      </section>

      {/* Courses section */}
      <section className="bg-white">
        <Container align="center">
          <Box py={6}>
            <Box mb={3}>
              <Typography variant="h3" className="font-semibold text-gray-700">
                {data.title3.title}
              </Typography>
            </Box>
            <Typography
              variant="h6"
              className="text-gray-700"
              style={{ maxWidth: '700px' }}
            >
              {data.title3.subtitle}
            </Typography>
          </Box>

          <div className="grid grid-cols-3">
            <div className="col-span-3 md:col-span-1 p-3 rounded-md max-w-xl my-0 mx-auto">
              <div className="bg-green-500 rounded-md">
                <Box py={7} px={1}>
                  <Box py={2}>
                    <Typography
                      variant="h5"
                      className="font-semibold text-white"
                    >
                      {data.course1.title}
                    </Typography>
                  </Box>
                  <Typography className="text-white">
                    {data.course1.subtitle}
                  </Typography>
                  <Link href="/courses">
                    <Button className="text-white py-1 px-3 font-bold tracking-wider my-2 mx-0 hover:bg-white hover:text-gray-600">
                      {data.course1.buttonText}
                    </Button>
                  </Link>
                </Box>
              </div>
            </div>

            <div className="col-span-3 md:col-span-1 p-3 rounded-md max-w-xl my-0 mx-auto">
              <div className="bg-blue-500 p-3 rounded-md max-w-xl my-0 mx-auto">
                <Box py={7} px={1}>
                  <Box py={2}>
                    <Typography
                      variant="h5"
                      className="font-semibold text-white"
                    >
                      {data.course2.title}
                    </Typography>
                  </Box>
                  <Typography className="text-white">
                    {data.course2.subtitle}
                  </Typography>
                  <Link href="/courses">
                    <Button className="text-white py-1 px-3 font-bold tracking-wider my-2 mx-0 hover:bg-white hover:text-gray-600">
                      {data.course2.buttonText}
                    </Button>
                  </Link>
                </Box>
              </div>
            </div>

            <div className="col-span-3 md:col-span-1 p-3 rounded-md max-w-xl my-0 mx-auto">
              <div className="bg-gray-600 p-3 rounded-md max-w-xl my-0 mx-auto">
                <Box py={7} px={1}>
                  <Box py={2}>
                    <Typography
                      variant="h5"
                      className="font-semibold text-white"
                    >
                      {data.course3.title}
                    </Typography>
                  </Box>
                  <Typography className="text-white">
                    {data.course3.subtitle}
                  </Typography>
                  <Link href="/courses">
                    <Button className="text-white py-1 px-3 font-bold tracking-wider my-2 mx-0 hover:bg-white hover:text-gray-600">
                      {data.course3.buttonText}
                    </Button>
                  </Link>
                </Box>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="col-span-3 md:col-span-1 p-3 rounded-md max-w-xl my-0 mx-auto">
              <div className="bg-gray-600 p-3 rounded-md max-w-xl my-0 mx-auto">
                <Box py={7} px={1}>
                  <Box py={2}>
                    <Typography
                      variant="h5"
                      className="font-semibold text-white"
                    >
                      {data.course4.title}
                    </Typography>
                  </Box>
                  <Typography className="text-white">
                    {data.course4.subtitle}
                  </Typography>
                  <Link href="/courses">
                    <Button className="text-white py-1 px-3 font-bold tracking-wider my-2 mx-0 hover:bg-white hover:text-gray-600">
                      {data.course4.buttonText}
                    </Button>
                  </Link>
                </Box>
              </div>
            </div>

            <div className="col-span-3 md:col-span-1 p-3 rounded-md max-w-xl my-0 mx-auto">
              <div className="bg-green-500 p-3 rounded-md max-w-xl my-0 mx-auto">
                <Box py={7} px={1}>
                  <Box py={2}>
                    <Typography
                      variant="h5"
                      className="font-semibold text-white"
                    >
                      {data.course5.title}
                    </Typography>
                  </Box>
                  <Typography className="text-white">
                    {data.course5.subtitle}
                  </Typography>
                  <Link href="/courses">
                    <Button className="text-white py-1 px-3 font-bold tracking-wider my-2 mx-0 hover:bg-white hover:text-gray-600">
                      {data.course5.buttonText}
                    </Button>
                  </Link>
                </Box>
              </div>
            </div>

            <div className="col-span-3 md:col-span-1 p-3 rounded-md max-w-xl my-0 mx-auto">
              <div className="bg-blue-500 p-3 rounded-md max-w-xl my-0 mx-auto">
                <Box py={7} px={1}>
                  <Box py={2}>
                    <Typography
                      variant="h5"
                      className="font-semibold text-white"
                    >
                      {data.course6.title}
                    </Typography>
                  </Box>
                  <Typography className="text-white">
                    {data.course6.subtitle}
                  </Typography>
                  <Link href="/courses">
                    <Button className="text-white py-1 px-3 font-bold tracking-wider my-2 mx-0 hover:bg-white hover:text-gray-600">
                      {data.course6.buttonText}
                    </Button>
                  </Link>
                </Box>
              </div>
            </div>
          </div>

          <Box py={4}>
            <Typography
              variant="h6"
              style={{ maxWidth: '700px' }}
              className="font-semibold text-gray-700"
            >
              {data.title4.subtitle}
            </Typography>
          </Box>
          <Box pb={12}>
            <Link href="/courses">
              <Button
                bgColor="bg-green-500"
                hoverBgColor="bg-green-600"
                className="my-2 shadow-lg text-white py-3 px-5 font-bold hover:shadow-md tracking-wider"
              >
                {data.title4.buttonText}
              </Button>
            </Link>
          </Box>
        </Container>
      </section>

      {/* CTA section */}
      <section className="bg-blue-500">
        <Box py={1}>
          <Container align="center">
            <div className="grid grid-cols-3">
              <div className="col-span-3 md:col-span-2">
                <Box pt={1.5} my={4}>
                  <Typography variant="h6" className="text-white font-semibold">
                    {data.title5.title}
                  </Typography>
                </Box>
              </div>
              <div className="col-span-3 md:col-span-1">
                <Box my={2}>
                  <Link href="/courses">
                    <Button
                      bgColor="bg-green-500"
                      hoverBgColor="bg-green-600"
                      className="py-2 px-5 my-2 mx-0 font-bold"
                    >
                      {data.title5.buttonText}
                    </Button>
                  </Link>
                </Box>
              </div>
            </div>
          </Container>
        </Box>
      </section>

      {/* Features section */}
      <section className="bg-white">
        <Container align="center">
          <Box py={12}>
            <Box mb={3}>
              <Typography variant="h3" className="font-semibold text-gray-700">
                {data.title6.title}
              </Typography>
            </Box>
            <Box mb={7}>
              <Typography
                variant="h6"
                style={{ maxWidth: '700px' }}
                className="text-gray-700"
              >
                {data.title6.subtitle}
              </Typography>
            </Box>

            <div className="grid grid-cols-3">
              <div className="col-span-3 md:col-span-1 p-3 rounded-md max-w-xl my-0 mx-auto">
                <div className="p-3 rounded-md max-w-xl my-0 mx-auto">
                  <Box px={1} pt={4}>
                    <img
                      src="/images/landing/lectures.png"
                      alt="lectures"
                      className="w-32 h-32"
                    />
                    <Box m={1}>
                      <Typography
                        variant="h5"
                        className="font-semibold text-gray-700"
                      >
                        {data.feature1.title}
                      </Typography>
                    </Box>
                    <Typography className="text-gray-700">
                      {data.feature1.subtitle}
                    </Typography>
                  </Box>
                </div>
              </div>

              <div className="col-span-3 md:col-span-1 p-3 rounded-md max-w-xl my-0 mx-auto">
                <div className="p-3 rounded-md max-w-xl my-0 mx-auto">
                  <Box px={1} pt={4}>
                    <img
                      src="/images/landing/tests.png"
                      alt="lectures"
                      className="w-32 h-32"
                    />
                    <Box m={1}>
                      <Typography
                        variant="h5"
                        className="font-semibold text-gray-700"
                      >
                        {data.feature2.title}
                      </Typography>
                    </Box>
                    <Typography className="text-gray-700">
                      {data.feature2.subtitle}
                    </Typography>
                  </Box>
                </div>
              </div>

              <div className="col-span-3 md:col-span-1 p-3 rounded-md max-w-xl my-0 mx-auto">
                <div className="p-3 rounded-md max-w-xl my-0 mx-auto">
                  <Box px={1} pt={4}>
                    <img
                      src="/images/landing/smart-learning.png"
                      alt="lectures"
                      className="w-32 h-32"
                    />
                    <Box m={1}>
                      <Typography
                        variant="h5"
                        className="font-semibold text-gray-700"
                      >
                        {data.feature3.title}
                      </Typography>
                    </Box>
                    <Typography className="text-gray-700">
                      {data.feature3.subtitle}
                    </Typography>
                  </Box>
                </div>
              </div>
            </div>
          </Box>
        </Container>
      </section>

      <section className="bg-gray-100">
        <Container align="center">
          <Box pt={14}>
            <Box mb={1}>
              <Typography variant="h3" className="font-semibold text-gray-700">
                {data.title7.title}
              </Typography>
            </Box>
            <Box mb={5}>
              <Typography
                variant="h6"
                style={{ maxWidth: '700px' }}
                className="text-gray-700"
              >
                {data.title7.subtitle}
              </Typography>
            </Box>
          </Box>

          <Box pb={14}>
            <div
              className="grid grid-cols-2"
              style={{
                maxWidth: '850px',
              }}
            >
              <div className="col-span-2 md:col-span-1 p-3 rounded-md max-w-xl my-0 w-full mx-auto">
                <Box p={2}>
                  <Box py={5} className="bg-blue-500 border-t-md">
                    <Typography
                      variant="h4"
                      className="font-semibold text-white"
                    >
                      {data.plan1.title}
                    </Typography>
                  </Box>
                  <Box py={3} className="bg-white border-b-md shadow-lg">
                    <Typography className="text-gray-700 font-semibold">
                      {data.plan1.subtitle1}
                    </Typography>
                    <Typography className="text-gray-700">
                      {data.plan1.subtitle2}
                    </Typography>
                    <Typography className="text-gray-700">
                      {data.plan1.subtitle3}
                    </Typography>
                    <Typography className="text-gray-700">
                      {data.plan1.subtitle4}
                    </Typography>
                    <Link href="/courses">
                      <Button className="mt-6 bg-blue-500 shadow-lg text-white py-2 px-3 font-bold hover:bg-blue-600 hover:shadow-md">
                        {data.plan1.buttonText}
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </div>

              <div className="col-span-2 md:col-span-1 p-3 rounded-md max-w-xl my-0 w-full mx-auto">
                <Box p={2}>
                  <Box py={5} className="bg-blue-500 border-t-md">
                    <Typography
                      variant="h4"
                      className="font-semibold text-white"
                    >
                      {data.plan2.title}
                    </Typography>
                  </Box>
                  <Box py={3} className="bg-white border-b-md shadow-lg">
                    <Typography className="text-gray-700 font-semibold">
                      {data.plan2.subtitle1}
                    </Typography>
                    <Typography className="text-gray-700">
                      {data.plan2.subtitle2}
                    </Typography>
                    <Typography className="text-gray-700">
                      {data.plan2.subtitle3}
                    </Typography>
                    <Typography className="text-gray-700">
                      {data.plan2.subtitle4}
                    </Typography>
                    <Link href="/courses">
                      <Button className="mt-6 bg-blue-500 shadow-lg text-white py-2 px-3 font-bold hover:bg-blue-600 hover:shadow-md">
                        {data.plan2.buttonText}
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </div>

              <div className="col-span-2 md:col-span-1 p-3 rounded-md max-w-xl my-0 w-full mx-auto">
                <Box p={2}>
                  <Box py={5} className="bg-blue-500 border-t-md">
                    <Typography
                      variant="h4"
                      className="font-semibold text-white"
                    >
                      {data.plan3.title}
                    </Typography>
                  </Box>
                  <Box py={3} className="bg-white border-b-md shadow-lg">
                    <Typography className="text-gray-700 font-semibold">
                      {data.plan3.subtitle1}
                    </Typography>
                    <Typography className="text-gray-700">
                      {data.plan3.subtitle2}
                    </Typography>
                    <Typography className="text-gray-700">
                      {data.plan3.subtitle3}
                    </Typography>
                    <Typography className="text-gray-700">
                      {data.plan3.subtitle4}
                    </Typography>
                    <Link href="/courses">
                      <Button className="mt-6 bg-blue-500 shadow-lg text-white py-2 px-3 font-bold hover:bg-blue-600 hover:shadow-md">
                        {data.plan3.buttonText}
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </div>

              <div className="col-span-2 md:col-span-1 p-3 rounded-md max-w-xl my-0 w-full mx-auto">
                <Box p={2}>
                  <Box py={5} className="bg-blue-500 border-t-md">
                    <Typography
                      variant="h4"
                      className="font-semibold text-white"
                    >
                      {data.plan4.title}
                    </Typography>
                  </Box>
                  <Box py={3} className="bg-white border-b-md shadow-lg">
                    <Typography className="text-gray-700 font-semibold">
                      {data.plan4.subtitle1}
                    </Typography>
                    <Typography className="text-gray-700">
                      {data.plan4.subtitle2}
                    </Typography>
                    <Typography className="text-gray-700">
                      {data.plan4.subtitle3}
                    </Typography>
                    <Typography className="text-gray-700">
                      {data.plan4.subtitle4}
                    </Typography>
                    <Link href="/courses">
                      <Button className="mt-6 bg-blue-500 shadow-lg text-white py-2 px-3 font-bold hover:bg-blue-600 hover:shadow-md">
                        {data.plan4.buttonText}
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </div>
            </div>
          </Box>
        </Container>
      </section>
    </div>
  )
}

SchoolLandingPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SchoolLandingPage
