import apiRequest from 'Utils/apiRequest'
import browserStorageService from 'Services/browserStorageService'
import noAuthApiRequest from 'Utils/noAuthApiRequest'

export default {
  getPostSettings() {
    return apiRequest.get('/school/post-settings/').then((data) => {
      const settings = data.result
      browserStorageService.set('school.post.settings', settings)
      return settings
    })
  },
  savePostSettings(data) {
    return apiRequest.patch('/school/post-settings/', data)
  },
  getDetail(schoolId) {
    return apiRequest.get(`/admin/school/${schoolId}/`)
  },
  getPaymentProviders() {
    return apiRequest.get(`/admin/school/payment-provider/`)
  },
  createPaymentProvider(data) {
    return apiRequest.post(`/admin/school/payment-provider/`, data)
  },
  updatePaymentProvider(providerId, data) {
    return apiRequest.patch(
      `/admin/school/payment-provider/${providerId}/`,
      data
    )
  },
  editSchool(schoolId, data) {
    return apiRequest.patch(`/admin/school/${schoolId}/`, data)
  },
  removeAdmin(schoolId, data) {
    return apiRequest.post(`/admin/school/${schoolId}/?type=removeAdmin`, data)
  },
  removeFaculty(schoolId, data) {
    return apiRequest.post(
      `/admin/school/${schoolId}/?type=removeFaculty`,
      data
    )
  },
  removeStudent(schoolId, data) {
    return apiRequest.post(
      `/admin/school/${schoolId}/?type=removeStudent`,
      data
    )
  },
  getSchools(query, page) {
    return apiRequest.get('/admin/school/', {
      params: { query, page },
    })
  },
  getSchoolUniqueIDs(query, page) {
    return apiRequest.get('/admin/school/uniqueID/', {
      params: { query, page },
    })
  },
  testSMSSenderID(data) {
    return apiRequest.post('/admin/school/testsms/', data)
  },
  editSchoolUniqueID(objId, data) {
    return apiRequest.patch(`/admin/school/uniqueID/${objId}/`, data)
  },
  createSchool(data) {
    return apiRequest.put('/admin/school/', data)
  },
  getSchoolAppData(schoolId) {
    return apiRequest.get(`/admin/school/${schoolId}/appdata/`)
  },
  editSchoolAppData(schoolId, data) {
    return apiRequest.patch(`/admin/school/${schoolId}/appdata/`, data)
  },
  getPinnedAnnouncement(invalidateCache) {
    let p = null
    const pa = browserStorageService.get('school.pinned.announcement')
    if (pa && !invalidateCache) {
      p = Promise.resolve(pa)
    } else {
      p = noAuthApiRequest.get('/school/pinnedannouncement/').then((data) => {
        const newPa = data.result
        browserStorageService.set('school.pinned.announcement', newPa)
        return newPa
      })
    }
    return p
  },
  savePinnedAnnouncement(pa) {
    return apiRequest.patch('/school/pinnedannouncement/', pa)
  },
  getLandingPageData() {
    // use no auth api request
    return noAuthApiRequest
      .get('/admin/school/landingpage/')
      .then((data) => data.result)
  },
  saveLandingPageData(inputData) {
    return apiRequest
      .patch('/admin/school/landingpage/', inputData)
      .then((data) => data.result)
  },
  toggleLandingPageEnabled() {
    return apiRequest
      .post('/admin/school/landingpage/')
      .then((data) => data.result)
  },
  sendAnnouncement(data) {
    return apiRequest.post('/admin/school/announcement/', data)
  },
  getSchoolAdmins(schoolId, page) {
    return apiRequest.get(`/admin/school/${schoolId}/?type=admins`, {
      params: { page },
    })
  },
  getAccountStats(courseId) {
    return apiRequest.get('/admin/school/account-stats/', {
      params: { courseId },
    })
  },
  getPaymentRecords(courseId, page) {
    return apiRequest.get('/admin/school/payment-records/', {
      params: { page, courseId },
    })
  },
  getActiveStudentsStats(courseId) {
    return apiRequest.get('/admin/school/weekly-stats/?type=activeStudents', {
      params: { courseId },
    })
  },
  getAverageTimeStats(courseId) {
    return apiRequest.get('/admin/school/weekly-stats/?type=averageTime', {
      params: { courseId },
    })
  },
  getActivityLogs(courseId, page) {
    return apiRequest.get('/admin/school/activity-logs/', {
      params: { courseId, page },
    })
  },
}
